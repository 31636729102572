@font-face {
  font-family: "Lulo clean";
  src: url("../src/fonts/Lulo\ Clean\ W01\ One\ Bold.ttf") format("truetype");
  font-family: "avenir";
  src: url("../src/fonts/AvenirLTStd-Black.otf") format("opentype");
  font-family: "futura";
  src: url("../src/fonts/Futura\ Light\ font.ttf") format("truetype");
  font-family: "futura-bt";
  src: url("../src/fonts/futura\ light\ bt.ttf") format("truetype");
}
html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}
