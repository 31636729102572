.insights-bg{
    background-image: linear-gradient(
      to bottom,
      rgba(237, 238, 244, 0.52),
      rgba(214, 214, 214, 0.73)
    ),url("../assets/promotersbg1.webp");
    background-color: transparent;
    background-size:cover;
    background-position: center;
    width: 100%;
    background-attachment: fixed;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.file-background{
    background-color: #fff;
    width: 75%;
    margin-bottom: 200px;
    margin-top: 150px;
    padding: 50px;
    
 /* align-self: center; */
}
.file-top-container{
    display: flex;
    justify-content: space-between !important;
    align-items: center;

}
.file-sub-container{
    display: flex;
   justify-content: space-between;
    align-items: center;
}
.date-container{
 display:flex;
 justify-content: center;
 align-items: center;
}
.checkbox:hover{
    background-color: #b8cfed;
    /* height: 100%;
    width: 100%;
    margin: 0; */
}
.insight-line{
    background-color: black;
}
.check-box{
    /* width: 10px;
    height: 10px; */
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 10px;
}
.count{
    text-align: left;
}


@media screen and (min-width:320px) {
    .file-background
    {
        width: 100%;
        padding: 20px;
        text-align: left;
    }

    
}

@media screen and (min-width:768px) {
    .file-background
    {
        width: 70%;
    }
    .check-box{
        width: 20px;
        height: 20px;
        border: 1px solid black;
        border-radius: 50%;
        margin-right: 10px;
    }
}
    
