@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.home-bg-container {
  background-image: url(../assets/background-city.jpg);
  background-size: cover;
  height: 130vh;
  background-attachment: fixed;
  /* background-position: center; */
  padding-top: 150px;
  padding-bottom: 150px;
}

.home-text-container {
  background-image: url("../assets/background2.webp");
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  top: 0;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text-health {
  background-color: #6a5f53;
  padding-top: 30px;
  height: 60vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "futura-bt";
}

.portfolio {
  margin-bottom: 40px;
  background-color: #9f8f7c;
  color: #fff;
  width: 160px;
  text-align: center;
  height: 40px;
  border-style: none;
  border-width: none;
  margin-top: 60px;
}

.link {
  text-decoration: none;
}
.scroll-text {
  text-align: center;
  width: 60%;
  position: relative;
}
.home-head1 {
  font-size: 66px;
  font-family: "Poppins", sans-serif;
}
.home-para1 {
  font-size: 42px;
  font-family: "Poppins", sans-serif;
}
.home-head2 {
  font-size: 36px;
  font-weight: lighter;
}
.home-para2 {
  font-size: 20px;
  text-align: center;
  font-weight: lighter;
  width: 50%;
}

.line {
  width: 70px;
  height: 2px;
  margin-bottom: 30px;
  background-color: #000;
}
@media screen and (min-width: 320px) {
  .home-head1 {
    font-size: 18px;
  }
  .home-para1 {
    font-size: 13px;
  }
  .home-head2 {
    font-size: 22px;
  }
  .home-para2 {
    font-size: 16px;
    width: 85%;
  }
  .portfolio {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .home-bg-container {
    height: 100vh;
  }
}

@media screen and (min-width: 600px) {
  .home-head1 {
    font-size: 28px;
  }
  .home-para1 {
    font-size: 22px;
  }
  .home-head2 {
    font-size: 30px;
  }
  .home-para2 {
    font-size: 20px;
    width: 80%;
  }
}

@media screen and (min-width: 1000px) {
  .home-head1 {
    font-size: 38px;
  }
  .home-para1 {
    font-size: 30px;
  }
  .home-head2 {
    font-size: 36px;
  }
  .home-para2 {
    font-size: 26px;
  }
  .portfolio {
    margin-top: 20px;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1300px) {
  .home-head1 {
    font-size: 48px;
  }
  .home-para1 {
    font-size: 40px;
  }
  .home-head2 {
    font-size: 42px;
  }
  .home-para2 {
    font-size: 32px;
  }
  .portfolio {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .text-health {
    height: 75vh;
  }
  .home-bg-container {
    height: 130vh;
  }
}
