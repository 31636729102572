.career-main-bg {
  background-image: url("../assets/careerbg.webp");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.career-sub-container {
  background-color: rgb(255, 97, 97);
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "futura";
  color: white;
}
.career-text-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.career-btn {
  width: 160px;
  align-self: center;
  margin-top: 50px;
  background-color: black;
  color: #fff;
  border-width: none;
  border: none;
  font-size: 15px;
  height: 40px;
}
.career-heading {
  font-size: 48px;
  font-family: "Lulo clean";
  font-weight: bold;
  color: white;
  width: 50%;
  text-align: center;
  align-self: center;
}
.career-para {
  font-size: 21px;
  font-family: "avenir";
  color: white;
  width: 70%;
  text-align: center;
  font-weight: 800;
  align-self: center;
}

.career-heading2 {
  font-size: 42px;
  font-weight: 100;
  letter-spacing: 2px;
}
.career-para2 {
  font-size: 22px;
  width: 40%;
  text-align: center;
  align-self: center;
}
.line {
  width: 70px;
  height: 2px;
  margin-bottom: 40px;
  background-color: #fff;
}
.career-btn:hover {
  background-color: #fff;
  color: black;
}
.career-btn:focus {
  outline: none;
}
@media (min-width: 320px) {
  .career-heading {
    font-size: 20px;
    width: 80%;
  }
  .career-para {
    font-size: 14px;
    width: 95%;
  }
  .career-heading2 {
    font-size: 17px;
  }
  .career-para2 {
    font-size: 12px;
    width: 100%;
  }
  .career-btn {
    width: 100px;
    font-size: 12px;
    height: 30px;
  }
  .career-text-container {
    height: 75vh;
  }
}
@media screen and (min-width: 600px) {
  .career-heading {
    font-size: 28px;
  }
  .career-para {
    font-size: 16px;
  }
  .career-heading2 {
    font-size: 28px;
  }
  .career-para2 {
    font-size: 20px;
    width: 90%;
  }
  .career-btn {
    width: 100px;
    font-size: 14px;
    height: 35px;
  }
  .career-text-container {
    height: 85vh;
  }
}
@media screen and (min-width: 1000px) {
  .career-heading {
    font-size: 38px;
  }
  .career-para {
    font-size: 24px;
  }
  .career-heading2 {
    font-size: 32px;
  }
  .career-para2 {
    font-size: 26px;
    width: 70%;
  }
  .career-btn {
    width: 130px;
    font-size: 15px;
    height: 40px;
  }
  .career-text-container {
    height: 95vh;
  }
}
@media (min-width: 1300px) {
  .career-heading {
    font-size: 48px;
    width: 50%;
  }
  .career-para {
    font-size: 21px;
    width: 70%;
  }
  .career-heading2 {
    font-size: 42px;
  }
  .career-para2 {
    font-size: 22px;
    width: 40%;
  }
  .career-btn {
    width: 160px;
    font-size: 15px;
    height: 40px;
  }
  .career-text-container {
    height: 100vh;
  }
}
