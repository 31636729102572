.link {
  text-decoration: none;
  color: #000;
}

.top-container {
  background-color: #00305b;
  /* width: 50%; */
  /* margin-bottom: auto; */
  /* height: 76vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* position: relative; */
}

.main-container {
  width: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* position: relative; */
  margin-bottom: auto;
  color: #fff;
}
.top-heading {
  font-size: 58px;
  color:#fff;
}
.top-para {
  font-size: 17px;
  color: #fff;
}
.img-top {
  width: 100%;
  height: 100%;
}

.t-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* height: 100vh; */
}

.imgs-container {
  height: 350px;
  width: 350px;
}

.color2 {
  background-color: rgb(222, 11, 11);
}

.text-bg {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.portfolio-heading {
  color: #ff6161;
  font-family: "futura-bt", sans-serif;
  font-weight: 100;
}
.portfolio-para {
  font-size: 15px;
  font-family: "futura-bt", sans-serif;
  font-weight: 100;
}
.portfolio-btn {
  border-color: #ff6161;
  font-family: "futurna-bt", sans-serif;
  border-width: 1px;
  width: 120px;
  height: 35px;
  color: #ff6161;
  background-color: transparent;
  margin-top: 30px;
}
.portfolio-btn:hover {
  background-color: #ff6161;
  color: #fff;
}

@media screen and (max-width: 320px) {
  .top-heading {
    font-size: 22px;
  }
  .top-para {
    font-size: 14px;
  }
}


.empty-div{
  height:100px;
  width: 100px;
  background-color: #fff;
}