.imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.img {
  width: 433px;
  height: 430px;
  margin: 30px 30px 0px 30px;
}
.p-bg {
  background-color: #fff;
  background-color: transparent;
}
.p-container {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      to bottom,
      rgba(104, 122, 224, 0.52),
      rgba(214, 209, 213, 0.73)
    ),
    url("../assets/promotersbg1.webp");
  background-size: cover;
  background-color: transparent;
  width: 100%;
  color: #000;
}

.p-name {
  /* text-align: center; */
  font-size: 20px;
  font-family: "playfair display serif";
}
.p-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.p-text {
  text-align: left;
  padding-left: 30px;
}

.promoter-down-container {
  background-image: url("../assets/promotersbg.webp");
  background-size: cover;
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.promoters-logo-imgs {
  width: 300px;
  height: 300px;
}

@media screen and (min-width: 320px) {
  .promoters-heading {
    font-size: 30px;
  }
  .promoters-para1 {
    font-size: 17px;
    font-family: playfair display serif;
  }
  .promoters-para2 {
    font-size: 17px;
  }
  .promoters-heading2 {
    font-size: 24px;
  }
  .line {
    width: 70px;
    height: 2px;
    margin-bottom: 30px;
    background-color: #fff;
  }
  .img {
    width: 250px;
    height: 250px;
    margin: 30px 30px 0px 30px;
  }
  .logo-img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 600px) {
  .promoters-heading {
    font-size: 50px;
  }
  .promoters-para1 {
    font-size: 30px;
    font-family: playfair display serif;
  }
  .promoters-para2 {
    font-size: 30px;
  }
  .promoters-heading2 {
    font-size: 30px;
  }
  .line {
    width: 100px;
    height: 2px;
    margin-bottom: 30px;
    background-color: #fff;
  }
}

@media screen and (min-width: 1000px) {
  .promoters-heading {
    font-size: 60px;
  }
  .promoters-para1 {
    font-size: 40px;
    font-family: playfair display serif;
  }
  .promoters-para2 {
    font-size: 30px;
  }
  .promoters-heading2 {
    font-size: 35px;
  }
  .line {
    width: 150px;
    height: 2px;
    margin-bottom: 30px;
    background-color: #fff;
  }
}

@media screen and (min-width: 1300px) {
  .promoters-heading {
    font-size: 60px;
    margin: 0px;
  }
  .promoters-para1 {
    font-size: 30px;
    font-family: playfair display serif;
    margin: 0px;
  }
  .promoters-para2 {
    font-size: 24px;
    font-weight: bold;
    margin: 80px;
    width: 70%;
  }
  .promoters-heading2 {
    font-size: 40px;
  }
  .line {
    width: 200px;
    height: 2px;
    margin-bottom: 30px;
    background-color: #fff;
  }
  .promoters-top-container {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .img {
    width: 443px;
    height: 443px;
    margin: 30px 30px 0px 30px;
  }
}
