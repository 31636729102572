.c-container {
  background-color: #080808;
  color: rgb(32, 29, 29);
  display: flex;
  flex-direction: column;

  /* align-items: center; */
}

.contact-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

@media screen and (min-width: 768px) {
  .contact-container {
    flex-direction: row;
  }
}

.text-container {
  background-color: #fff;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .text-container {
    margin-right: auto;
    padding: 20px;
    padding-left: 200px !important;
  }
}

.contact-container-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .contact-container-2 {
    width: 30vw;
  }
}

.c-text {
  margin-left: 10px;
  font-size: 13px;
  text-align: left;
}

.icons-container {
  display: flex;
  margin-top: 15px;
  /* padding-left: 8px; */
}

.para {
  margin-top: 30px;
  font-size: 10px;
  display: flex;
  padding-top: 10px;
  /* padding-left: 30px; */
  /* padding-right: 10px; */
  justify-content: space-around;
  color: #f7f7ed;
}
@media screen and (min-width: 768px) {
  .para {
    font-size: 15px;
  }
}

.img-logo {
  /* width: 160px;
  height: 120px;
  padding: 25px;
  margin-left: 30px; */
  width: 100px;
  height: 65px;
  padding: 5px;
  margin-left: 20px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  .img-logo {
    width: 160px;
    height: 120px;
    padding: 15px;
    margin-left: 10px;
  }
}

.icon {
  margin-left: 5px;
}

.bottom-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

@media screen and (min-width: 1220px) {
  .para {
    font-size: 14px;
  }
  .c-text {
    margin-left: 30px;
    font-size: 16px;
    text-align: left;
  }
}

/* .icon-container {
} */
